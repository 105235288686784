import React from "react"

const Footer = ({ pages }) => (
  <div className="document-wrapper-foot">
    <div className="footer-3-and-4">
      <div className="footer-3-and-4-inner">
        <div className="region region-footer-3">
          <nav role="navigation" aria-labelledby="block-footer-utility-menu" id="block-footer-utility" className="navigation menu--footer">
            <ul className="menu">
            <li className="menu-item menu-item--not-overview">
              <a href="https://www.nicb.org/terms-conditions">Terms and Conditions</a>
            </li>
            <li className="menu-item menu-item--not-overview">
              <a href="https://www.nicb.org/privacy-policy">Privacy Policy</a>
            </li>
            </ul>
          </nav>
          <div id="block-nicbcopyright" className="block block-block-content">
            <div className="clearfix text-formatted field field--name-body field--type-text-with-summary field--label-hidden field__item">
              <p>
                <sup>&copy;</sup>{new Date().getFullYear()}&nbsp;NICB<br />Reproduction or republication strictly prohibited without prior written permission.
              </p>
            </div>
          </div>
        </div>
        <div className="region region-footer-4">
          <div id="block-nicbcontactinfo" className="block block-block-content">
            <div className="clearfix text-formatted field field--name-body field--type-text-with-summary field--label-hidden field__item">
              <p>Cargo theft is a national security issue and a major problem plaguing our economy. The goal of the website is to facilitate a collaborative effort to combat this serious crime. </p>
              <p>For additional information, please contact:</p>
              <address>
                Email: <a href="mailto:NCTTF@nicb.org">NCTTF@nicb.org</a><br />
              </address>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default Footer
