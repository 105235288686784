import { Link } from "gatsby"
import React, { Component } from "react"
import Logo from "./logo"
import NavLink from "../components/nav-link"

class Header extends Component {

  constructor(props) {
    super(props);
    this.state = {isMobileMenuActive: false};

    // This binding is necessary to make `this` work in the callback
    this.menuToggle = this.menuToggle.bind(this);
  }

  menuToggle() {
    this.setState(state => ({
      isMobileMenuActive: !state.isMobileMenuActive
    }));
  }

  render() {
    // let logoStyles = {};
    return (
      <div className="document-wrapper-head">
        <div className="document-wrapper-head-inner">
          <div className="region region-global-branding">
            <div className="logo-header" style={{marginTop: '2px'}}>
              <Link to="/">
                <Logo fill="#006838"/>
              </Link>
            </div>
          </div>
          <div className="header-mobile-toggles">
            <button onClick={this.menuToggle} type="button" className="main-menu-toggle">
              <span className="icon"></span>
              <span className="text">Menu</span>
            </button>
          </div>
          <div className="region region-global-utility">
            <div className="utility-button-container">
              <Link className="nicb-button" to="/become-a-member">Become a Member</Link>
            </div>
          </div>
          <div className={(this.state.isMobileMenuActive ? 'active' : '') + ' region region-global-navigation'}>
            <nav role="navigation" className="navigation menu--main">
              <ul className="menu">
                { this.props.pages.map((page) => {
                  if (page.menu) {
                    return (
                      <NavLink key={page.name} to={page.link}>{page.name}</NavLink>
                    )
                  }

                  return (null);
                })}
              </ul>
              <Link style={{paddingLeft: '60px', color: '#fff', fontSize: '20px'}} className="become-member" to="/become-a-member">Become a Member</Link>
            </nav>
          </div>
        </div>
      </div>
    );
  }
}

export default Header
