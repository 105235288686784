import React from "react"
import { Link } from "gatsby"

const Breadcrumbs = ({pageTitle }) => {
  return (
    <div className="breadcrumbs-container">
      <nav className="breadcrumb" role="navigation" aria-labelledby="system-breadcrumb">
        <ol>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
           {pageTitle}
          </li>
        </ol>
      </nav>
    </div>
  )
}

export default Breadcrumbs
