import React from 'react';

const Logo = ({fill}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 319.03 247.73">
      <defs/>
      <g fill={fill} id="Layer_2" data-name="Layer 2">
        <g id="Layer_2-2" data-name="Layer 2">
          <path d="M282.79 18.37a1.55 1.55 0 0 0-1.74-1.37l-96.32 12.83-.47-9.53a1.3 1.3 0 0 0-.41-.88l-2.35-2.21a1.34 1.34 0 0 0-2.23.66l-3 12.62-10.2.94-1 .15C152.86 23.22 117.75 0 105 0c-4 0-8.47 2.28-9.61 4.46s1.26 9.94 10.93 16.81c5.66 4 16.58 11.62 24.78 17.3L103.91 49l-17.63 6.75-13.92 5.46a.63.63 0 0 0 0 1.17l6.14 2.48a1 1 0 0 0 .61 0l51.14-8 20-1.54s10.21 5.44 19.9 5.4c20.93-.06 20.34-14.6 25.6-17.85l86.68-19.67a1 1 0 0 0 .77-1.07zM78.05 112.3a1.72 1.72 0 0 0-2.05-1.68c-2.16.46-9.29 1.16-12.43 1.59-5 .68-7.71 11.34-7.71 11.34h-9.7a4.84 4.84 0 0 0-4.82 4.37L40 142.14h-4.58v5h7a9 9 0 0 0-.2 1.81 8.62 8.62 0 1 0 17.24 0 9 9 0 0 0-.2-1.81h29.65a8.3 8.3 0 0 0-.2 1.81 8.62 8.62 0 1 0 17.23 0 8.3 8.3 0 0 0-.2-1.81h6.75a8.3 8.3 0 0 0-.2 1.81 8.62 8.62 0 0 0 8.62 8.62 8.53 8.53 0 0 0 2.27-.34v-19.4H78.05z" className="cls-1"/>
          <path d="M160.02 100.19H83.38v32.65h39.79v-3.19l36.85-7.68v-21.78zM165.49 161.6h40.22v-43.59l-40.22 8.38v35.21zM165.49 120.83l40.22-8.38v-51.7l-40.22 20.79v39.29zM211.15 112.2l62.59 10.97v-27.5l-62.59-34.92v51.45zM211.15 117.67v43.93h62.59v-32.96l-62.59-10.97zM128.62 161.6h31.43v-34.07l-31.43 6.55v27.52zM51.45 208.18l-29.33-37.36H0v74.97h23.52v-37.36l29.33 37.36h22.03v-74.97H51.45v37.36zM149.07 193.67h14.85v52.12h23.52v-52.12h14.84v-22.85h-53.21v22.85zM211.31 193.67h14.85v52.12h23.52v-52.12h14.84v-22.85h-53.21v22.85zM319.03 191.57v-20.75h-45.28v74.97h23.51V218.5h19.83v-18.04h-19.83v-8.89h21.77zM139.59 217.34l-.85.91c-4.11 4.38-8.56 6.51-13.6 6.51a15.72 15.72 0 0 1-14.77-9.92 17.39 17.39 0 0 1-1.23-6.63 17.1 17.1 0 0 1 1.23-6.53 15.89 15.89 0 0 1 3.34-5.16 15.5 15.5 0 0 1 5.07-3.41 16.27 16.27 0 0 1 6.45-1.26c5.34 0 9.72 2.17 13.39 6.65l1 1.19v-27.86a51.68 51.68 0 0 0-6.73-2 41.18 41.18 0 0 0-8.74-.93A39.24 39.24 0 0 0 88 192.3a40.32 40.32 0 0 0 0 31.74 41 41 0 0 0 8.64 12.53A39 39 0 0 0 124 247.73a40.63 40.63 0 0 0 7.62-.74 58 58 0 0 0 7.94-2.21z" className="cls-1"/>
        </g>
      </g>
    </svg>
  )
}

export default Logo
