import React from 'react';
import { Match, Link } from '@reach/router';

const NavLink = ({to, children, className}) => {
  return (
    <Match path={to}>
      {props =>
        <li className={'menu-item ' + (props.match ? 'menu-item--active-trail' : '') + className}>
          <Link to={to}>{children}</Link>
        </li>
      }
    </Match>
  )
}

export default NavLink
