/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
import Header from "./header"
import Footer from "./footer"
import IconFont from "./iconFont"
import Breadcrumbs from "../components/breadcrumbs"

const Layout = ({ children, pageTitle, pathName, hasBreadcrumbs, hasSidebar}) => {
  if (typeof hasBreadcrumbs === 'undefined') {
    hasBreadcrumbs = true;
  }

  if (typeof hasSidebar === 'undefined') {
    hasSidebar = true;
  }

  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
              pages {
                name
                link
                menu
              }
            }
          }
        }
      `}

      render={ data => (
        <div className="document-wrapper">
          <IconFont/>
          <Header pages={data.site.siteMetadata.pages} />
          <div className="document-wrapper-body">
            <div className="region region-page-header">
              {(() => {
                if (hasBreadcrumbs) {
                  return (
                    <Breadcrumbs pageTitle={pageTitle} />
                  )
                }
              })()}
              <div className="page-title-container">
                <h1 className="page-title">{pageTitle}</h1>
              </div>
            </div>
            <div className="main-container">
              <div className="main-container-inner">
                <div className="main-container-content" style={{marginBottom: '25px'}}>{children}</div>

                {(() => {
                  if (hasSidebar) {
                    return (
                      <div className="main-container-sidebar">
                        <div className="ncttf-callout">
                          <p><strong>SPEAK UP</strong></p>
                          <p>
                            To report fraud, call <a href="tel:800.835.6422">800.TEL.NICB</a> or provide an <a href='https://www.nicb.org/how-we-help/report-fraud'>online tip</a>.
                          </p>
                        </div>
                      </div>
                    )
                  }
                })()}
              </div>
            </div>
          </div>
          <Footer pages={data.site.siteMetadata.pages} />
        </div>
      )}
    />
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

Layout.propTypes = {
  hasBreadcrumbs: PropTypes.bool,
}

Layout.propTypes = {
  hasSidebar: PropTypes.bool,
}

Layout.propTypes = {
  pathName: PropTypes.string.isRequired,
}

export default Layout
