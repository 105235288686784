import { createGlobalStyle } from "styled-components";
import {SdIconFontTTF, SdIconFontWoff, SdIconFontWoff2, SdIconFontEOT, SdIconFontSVG} from '../fonts/fonts.js';

const iconFont = createGlobalStyle `
  @font-face {
    font-family: 'sd-icon-font';
    src: url('${SdIconFontEOT}');
    src: url('${SdIconFontEOT}#iefix') format('embedded-opentype'),
    url('${SdIconFontWoff2}') format('woff2'),
    url('${SdIconFontWoff}') format('woff'),
    url('${SdIconFontTTF}') format('truetype'),
    url('${SdIconFontSVG}#sd-icon-font') format('svg');
    font-weight: normal;
    font-style: normal;
  }
`

export default iconFont;